@import 'styles/import.scss';

.teaser {
  padding-top: calc($header-height-sm + 48px);
  color: $white;
  position: relative;
  overflow: hidden;

  &.konto {
    margin-bottom: 160px;
    &:after {
      height: 100%;
    }

    :local {
      .label {
        margin-bottom: 32px;
      }
      .moodleButton {
        margin-top: 16px;
        font-family: $font-base;
      }
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 120px;
    }
    @include media-breakpoint-down(md) {
      :local {
        .subtitle {
          i {
            display: block;
            width: 0;
            height: 0;
            margin: 0;
          }
        }
      }
    }
    @include media-breakpoint-up(md) {
      :local {
        .label {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
        }
        .subtitle {
          margin-bottom: 0;
        }
        .moodleButton {
          margin-top: 0;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      :local {
        .label {
          margin-bottom: 40px;
        }
      }
    }
  }

  :global {
    .slick-arrow {
      width: 40px;
      height: 40px;
      bottom: -13px;
      top: calc(100% + 64px);
      z-index: 2;

      &::before {
        content: '';
      }

      &:hover {
        svg * {
          stroke: $green;
        }
      }

      &.slick-disabled {
        svg * {
          stroke: $grey25;
        }
      }
    }

    .slick-next {
      right: 16px;
      transform: none;
    }

    .slick-prev {
      right: 80px;
      transform: rotate(180deg);
      left: auto;
    }

    .slick-slide {
      flex-shrink: 0;
      width: 324px;
      padding: 0 12px;

      @include media-breakpoint-up(xl) {
        width: 424px;
      }
      @include media-breakpoint-up(xxl) {
        width: 460px;
        padding: 0 16px;
      }
    }

    .slick-list {
      overflow: visible;
      margin: 0 -12px;
      @include media-breakpoint-up(xxl) {
        margin: 0 -16px;
      }
    }

    .slick-track {
      display: flex;
      width: 100% !important;
    }
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    background-color: $blue_light;
    height: calc(100% - 120px - 92px);
    width: 100%;
  }
  &.isExtended {
    &:after {
      height: calc(100% - 120px - 90px);
    }
    :local(.titleSubtitleWrapper) {
      margin-bottom: 64px;
    }
    :local(.imageVideoContainer) {
      @include make-col(3, 4);
      margin-left: auto;
    }
    :local(.labelButtonWrapper) {
      margin-bottom: 80px;
    }
  }
  &.isSlider,
  &.hasCaption {
    margin-bottom: 88px;
    &:after {
      height: calc(100% - 208px - 120px);
    }
  }
  @include media-breakpoint-up(md) {
    padding-top: calc($header-height-sm + 80px);
    &:after {
      height: calc(100% - 160px - 168px);
    }
    &.isExtended {
      &:after {
        height: calc(100% - 160px - 100px);
      }
      :local(.titleSubtitleWrapper) {
        margin-bottom: 48px;
      }
      :local(.imageVideoContainer) {
        @include make-col(6, 8);
      }
    }
    &.isSlider,
    &.hasCaption {
      margin-bottom: 104px;
      &:after {
        height: calc(100% - 264px - 120px);
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: calc($header-height + 80px);

    &:after {
      height: calc(100% - 160px - 200px);
    }
    &.isExtended {
      margin-bottom: 140px;
      &:after {
        height: calc(100% - 160px);
      }
      :local(.mediaTextWrapper) {
        position: relative;
      }
      :local(.imageVideoContainer) {
        height: auto;
        @include make-col(6);
        position: absolute;
        right: 0;
      }
      :local(.titleSubtitleWrapper) {
        margin-bottom: 96px;
      }
      :local(.labelButtonWrapper) {
        margin-top: 64px;
        margin-bottom: 48px;
        display: flex;
        flex-direction: column;
      }
    }
    &.isSlider {
      margin-bottom: 104px;
    }
  }

  @include media-breakpoint-up(xl) {
    padding-top: calc($header-height + 112px);
    &:after {
      height: calc(100% - 160px - 224px);
    }
    &.isExtended {
      :local(.titleSubtitleWrapper) {
        margin-bottom: 120px;
      }
      :local(.labelButtonWrapper) {
        margin-top: 156px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    &.isExtended {
      margin-bottom: 190px;
    }
  }
}

.imageVideoContainer {
  margin-left: auto;
  width: 100%;

  @include media-breakpoint-up(md) {
    @include make-col(5, 6);
    &.isSlider {
      @include make-col(6, 6);
    }
  }
  @include media-breakpoint-up(lg) {
    &.isSlider {
      @include make-col(10, 12);
    }
  }
}

.subtitle {
  color: $white;
  @include text-sm;
  margin-bottom: 8px;
  div {
    &:last-child {
      &::before {
        background: $white;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 12px;
  }
}

.title {
  hyphens: auto;
  hyphenate-limit-chars: auto 5;
  @include heading-2;
  margin: 0;
  @include media-breakpoint-up(md) {
    hyphens: none;
  }
  @include media-breakpoint-up(xxl) {
    @include heading-2;
  }
}

.titleSubtitleWrapper {
  margin-bottom: 80px;
  @include media-breakpoint-up(md) {
    @include make-col(5, 6);
    margin-bottom: 116px;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8, 12);
    margin-bottom: 120px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 160px;
  }
}

.teaserSubtitle {
  margin-top: 8px;
  @include text-lg;
}

.mediaTextWrapper {
  display: flex;
  flex-direction: column-reverse;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.label {
  margin-bottom: 16px;
  font-family: $font-mono;
  @include text-xs;
  @include media-breakpoint-up(lg) {
    margin-bottom: 20px;
  }
}

.button {
  &:hover {
    background: $blue;
  }
  &.isAnchor {
    color: $white;
    > div {
      &:after {
        background-color: $white;
      }
    }
    &:hover {
      background: transparent;
      color: $blue_spot;
      > div {
        &:after {
          background-color: $blue_spot;
        }
      }
      svg * {
        stroke: $blue_spot;
      }
    }
    svg {
      transform: rotate(90deg);
      * {
        stroke: $white;
      }
    }
  }
}

.slideImage {
  margin-bottom: 32px;
  height: 224px;
  cursor: pointer;
  > span {
    height: 100% !important;
  }
  @include media-breakpoint-up(xl) {
    height: 288px;
  }
  @include media-breakpoint-up(xxl) {
    height: 320px;
  }
}

.slideSubtitle {
  margin-bottom: 8px;
  color: $grey200;
  @include text-xs;
  i {
    background: $grey200;
  }
}

.slideTitle {
  color: $black;
  @include text-xl;
  @include gradient-underline;
  @include tn(color, 0.5s, 0s, ease-in-out);
  &:after {
    background: $green_light;
  }
  &:hover {
    color: $green;
  }
}

.sliderTitle {
  font-family: $font-mono;
  margin-bottom: 40px;
  @include text-sm;
  @include media-breakpoint-up(md) {
    margin-bottom: 48px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 56px;
  }
}

.caption {
  color: $grey200;
  @include text-sm;
  margin-top: 16px;
}
