@import 'styles/import.scss';

.select {
  margin-bottom: 32px;
  &:global(.has--full-width) {
    width: 100%;
  }
  label {
    margin-bottom: 4px;
  }
  &.isError {
    color: $red_error;
    label,
    label::after {
      color: $red_error;
    }
    select {
      border-color: $red_error;
    }
    svg * {
      fill: $red_error;
    }

    :local(.container) {
      &::after {
        background: $red_error;
      }
      &::before {
        background: $red_error_hover;
      }
    }

    &:hover {
      label,
      label::after {
        color: $red_error;
      }
      svg * {
        fill: $red_error_hover;
      }
    }
  }
  select {
    appearance: none;
  }

  @include media-breakpoint-up(md) {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    padding: 0 16px;
  }
}

.konto {
  margin-bottom: 40px;

  @include media-breakpoint-up(md) {
    margin-bottom: 48px;
  }
}

.selectWrapper {
  position: relative;

  svg {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    right: 8px;
    pointer-events: none;
  }
  &:hover {
    svg * {
      fill: $green;
    }
  }

  &.isGrey {
    color: $grey100;
  }
}

.container {
  @include green-underline;
  &:hover {
    label,
    label::after {
      @include tn(color, 0.5s, 0s, ease-in-out);
      color: $green_dark;
    }
  }
}

.description {
  @include text-xs;
  color: $grey200;
  margin-top: 6px;
}
