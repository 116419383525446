@import 'styles/import.scss';

.label {
  @include text-xs;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: $font-mono;
  &::before {
    content: '';
    margin-right: 12px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $green_light;
    display: block;
  }
}

.divider {
  border-top: 1px solid $grey25;
  padding-top: 16px;
  margin-bottom: 80px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 56px;
  }
}
