@import 'styles/import.scss';

.subtitleWithDot {
  font-family: $font-mono;
  i {
    border-radius: 50%;
    width: 4px;
    height: 4px;
    margin: 0 8px;
    background: white;
    display: inline-block;
    margin-bottom: 2px;
  }
}
